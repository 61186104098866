<template>
  <ion-item :lines="lines">
    <ion-label>
      <div class="h6">
        {{ resource.name }}
      </div>
      <div>{{ resource.location }}</div>
    </ion-label>
    <ion-chip slot="end">{{ resource.category }}</ion-chip>
  </ion-item>
</template>

<script>
import { IonChip, IonItem, IonLabel } from "@ionic/vue";

export default {
  props: {
    resource: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "full",
    },
  },
  components: {
    IonItem,
    IonLabel,
    IonChip
  },
};
</script>

<style scoped>
ion-badge {
  font-weight: 300;
}

ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}
</style>
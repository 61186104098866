<template>
  <base-layout-two page-title="Resources" page-default-back-link="/more" content-class="background-white">
    <section class="padding-x-16">
      <h1>Resources</h1>
      <ion-button class="regular-button" expand="block" router-link="/resources/create">
        New Resource
      </ion-button>
    </section>

    <ion-list class="margin-top-24">
      <ion-item-group v-for="category in resource_categories" :key="category">
        <ion-item-divider sticky>
          <ion-label>{{ category }}</ion-label>
        </ion-item-divider>

        <resource-list-item v-for="resource in resourcesInCategory(category)" :key="resource.id"
          :router-link="`/resources/${resource.id}`" :resource="resource">
        </resource-list-item>
      </ion-item-group>
    </ion-list>
  </base-layout-two>
</template>

<script>
import { IonList, IonItemGroup, IonItemDivider, IonLabel, IonButton } from "@ionic/vue";
import ResourceListItem from "@/components/resources/ResourceListItem.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    IonList, IonItemGroup, IonItemDivider, IonLabel, IonButton,
    ResourceListItem,
  },

  computed: {
    ...mapGetters(["globalProperty"]),
  },

  data() {
    return {
      resources: [],
      resource_categories: [],
    };
  },

  ionViewWillEnter() {
    this.fetchResources();
  },

  methods: {
    async fetchResources() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/resources?property_id=${this.globalProperty.id}`
        )
        .then((response) => {
          this.resources = response.data.resources;

          this.resources.sort(function (a, b) {
            return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
          })

          // Parse into Groups
          this.resource_categories = [...new Set(this.resources.map(item => item.category))]
          this.resource_categories.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1;
          })
        })
        .catch((error) => {
          console.log(error);
        });
    },

    resourcesInCategory(category) {
      return this.resources.filter((x) => x.category === category)
    }
  },
};
</script>